import {
  Badge,
  Button,
  Collapse,
  IconButton,
  Timeline,
} from '@finalytic/components';
import {
  useApiMutation,
  useGqtyClient,
  useInvalidateQueries,
  useMutation,
  useTeamId,
} from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import type { party_enum } from '@finalytic/graphql';
import {
  CalendarDatesIcon,
  CalendarEventIcon,
  CreditCardIncomeIcon,
  CrossCircleIcon,
  Edit3Icon,
  HomeIcon,
  Icon,
  LoaderIcon,
  UserIcon,
} from '@finalytic/icons';
import type { MRT_ColumnDef } from '@finalytic/table';
import {
  Drawer,
  EllipsisMenuCopyItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  StringParam,
  showErrorNotification,
  useQueryParamSet,
} from '@finalytic/ui';
import {
  type Maybe,
  formatCurrency,
  hasValue,
  sum,
  toTitleCase,
  uniqueBy,
  utc,
} from '@finalytic/utils';
import {
  Anchor,
  Avatar,
  Box,
  Card,
  Center,
  Group,
  HoverCard,
  LoadingOverlay,
  Progress,
  SimpleGrid,
  Stack,
  Text,
  Title,
  Tooltip,
  rem,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import {
  type ComponentPropsWithoutRef,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { ReservationStatusBadge } from '../../components';
import { ReservationPaymentStatusBadge } from '../../components/ReservationPaymentStatusBagde';
import { useExtractMutation } from '../../hooks';
import { useJournalEntriesModal } from '../../modals';
import { useListingDetailDrawer } from '../../views/listings/drawer';
import {
  DrawerCollapsableTable,
  DrawerHeader,
  DrawerInfoCard,
} from '../_components';
import { useDepositDetailDrawer } from '../deposit-drawer';
import {
  ReservationAdjustmentAddModal,
  ReservationAdjustmentDeleteModal,
  ReservationEditForm,
} from './_components';
import { useHasReservationUserDataEditAccess } from './_hooks/useHasReservationUserDataEditAccess';
import {
  type Reservation,
  useLedgerReservationDetailDrawerQuery,
} from './_queries/useLedgerReservationDetailDrawerQuery';
import { useReservationDetailDrawer } from './useReservationDetailDrawer';

function useReservationUpdateMutation() {
  const invalidate = useInvalidateQueries(['reservations']);
  const { mutateAsync: mutate, isPending: loading } = useApiMutation(
    'put',
    '/reservations/{id}',
    {
      onSuccess: () => {
        invalidate();
      },
      onError: (error: any) => {
        console.error(error);
        showErrorNotification({
          title: 'Failed to generate financials',
          message:
            error?.message ||
            'We failed to generate the financials. Please try again and contact support if the issue persists.',
        });
      },
    }
  );

  return {
    mutate,
    loading,
  };
}

export const LedgerReservationDetailDrawer = () => {
  const { colorScheme } = useMantineColorScheme();
  const { colors } = useMantineTheme();

  const { opened, close, reservationId, view, setView } =
    useReservationDetailDrawer();

  const { open: openJournalEntriesModal } = useJournalEntriesModal();
  const { loading, mutate } = useReservationUpdateMutation();
  const { mutate: extract, loading: loadingExtract } = useExtractMutation();

  const hasUserDataEditAccess = useHasReservationUserDataEditAccess();

  const { isLoading, data: reservation } =
    useLedgerReservationDetailDrawerQuery(reservationId);

  const updatePaymentData = useMutation(
    (
      q,
      { reservationId, userdata }: { reservationId: string; userdata: any }
    ) => {
      return q.updateReservation({
        pk_columns: {
          id: reservationId,
        },
        _set: {
          userdata,
        },
      })?.id;
    },
    {
      invalidateQueryKeys: ['reservations'],
    }
  );

  return (
    <Drawer opened={opened} onClose={close} size={550}>
      <DrawerHeader
        closeDrawer={close}
        title={
          reservation && (
            <Group mt={rem(5)} wrap="nowrap">
              <Avatar src={reservation.connection.app.iconRound} />
              <Box>
                <Text size="lg" fw={500}>
                  {reservation.guestName}
                </Text>
                <Text
                  size="xs"
                  c={colorScheme === 'dark' ? colors.gray[6] : 'gray'}
                  fw={400}
                >
                  {`${uniqueBy(
                    [
                      reservation?.confirmationCode,
                      reservation?.pmsReferenceCode,
                    ].filter((x) => x)
                  ).join(', ')}`}
                </Text>
              </Box>
            </Group>
          )
        }
        type="Reservation"
        loading={isLoading}
        menuItems={
          view === 'overview' &&
          reservation && (
            <>
              {hasUserDataEditAccess && (
                <EllipsisMenuItem
                  onClick={() => setView('edit')}
                  customIcon={<Edit3Icon size={16} />}
                >
                  Edit User Data
                </EllipsisMenuItem>
              )}

              {!!reservation.connection.id && (
                <EllipsisMenuItem
                  customIcon={<Icon icon={'RefreshCwIcon'} size={16} />}
                  loading={loadingExtract}
                  disabled={
                    !reservation.uniqueRef || !reservation.connection.id
                  }
                  onClick={() =>
                    extract({
                      connectionId: reservation.connection.id!,
                      extractor: 'reservation',
                      forceUpdate: true,
                      params: {
                        uniqueRef: reservation.uniqueRef,
                        date: reservation.checkIn,
                      },
                    })
                  }
                >
                  Refetch
                </EllipsisMenuItem>
              )}

              <EllipsisMenuItem
                onClick={() =>
                  openJournalEntriesModal({
                    reservationId: { _eq: reservation.id },
                  })
                }
                customIcon={<Icon icon="ListUnorderedIcon" size={16} />}
              >
                Journal Entries
              </EllipsisMenuItem>

              <EllipsisMenuDivider />

              <EllipsisMenuItem
                loading={loading}
                onClick={() =>
                  mutate({
                    params: {
                      path: {
                        id: reservation.id,
                      },
                    },
                    body: {
                      status:
                        reservation.status === 'cancelled'
                          ? 'booked'
                          : 'cancelled',
                    },
                  })
                }
                customIcon={<Icon icon="AlertTriangleIcon" size={16} />}
              >
                {reservation.status === 'cancelled'
                  ? 'Revert cancellation'
                  : 'Cancel reservation'}
              </EllipsisMenuItem>

              <HiddenFeatureIndicator permission="super-admin">
                <EllipsisMenuCopyItem value={reservation.id} />
              </HiddenFeatureIndicator>
            </>
          )
        }
      />
      {!reservation && !isLoading ? (
        'No reservation found'
      ) : view === 'edit' && reservation ? (
        <ReservationEditForm
          initialValues={{
            userData: JSON.stringify(reservation.userData || {}, null, 2),
          }}
          onReset={() => setView('overview')}
          isLoading={isLoading}
          handleSubmit={async (values) => {
            updatePaymentData
              .mutate({
                args: {
                  reservationId: reservation.id,
                  userdata: JSON.parse(values.userData?.trim() || '{}'),
                },
              })
              .then(() => setView('overview'));
          }}
        />
      ) : (
        <Content reservation={reservation} isLoading={isLoading || loading} />
      )}
    </Drawer>
  );
};

const Content = ({
  reservation,
  isLoading,
}: { reservation: Maybe<Reservation>; isLoading: boolean }) => {
  const { edit, remove } = useAdjustmentModals();

  if (!reservation) return null; // payments/resolutions grouped

  return (
    <Stack
      gap={'md'}
      mb="md"
      sx={{
        position: 'relative',
        flex: 1,
      }}
    >
      <ReservationAdjustmentAddModal
        closeModal={() => edit.setOpened(null)}
        reservationId={reservation.id}
        reservationLine={edit.opened}
      />
      <ReservationAdjustmentDeleteModal
        closeModal={() => remove.setOpened(null)}
        reservationLine={remove.opened}
        reservationId={reservation.id}
      />

      <LedgerReservationInfoCard {...reservation} />

      {/* Statistics */}
      {!reservation.financials.isCancelledAndPending && (
        <Statistics {...reservation} />
      )}

      <Financials
        title="Financials"
        financials={reservation.financials}
        reservationId={reservation.id}
        status={reservation.status}
        openDeleteAdjustment={remove.setOpened}
        openEditAdjustment={edit.setOpened}
      />

      <FeesAndCommissions
        title="Fees and Commissions"
        rowData={reservation.recurringFees}
        currency={reservation.currency}
        reservationId={reservation.id}
        openDeleteAdjustment={remove.setOpened}
        openEditAdjustment={edit.setOpened}
      />

      {/* Payments */}
      <Deposits
        title="Payments / Refunds"
        currency={reservation.currency}
        deposits={reservation.deposits}
      />

      <ReservationTimeline data={reservation.timeline} />

      <LoadingOverlay
        visible={isLoading}
        loaderProps={{
          size: 'sm',
        }}
      />
    </Stack>
  );
};

function useAdjustmentModals() {
  const [opened, setOpened] = useState<{
    reservationId: string;
    lineType: string | null;
    description: string;
    centTotal: number | undefined;
    party: party_enum;
  } | null>(null);

  const [deleteModalReservationLine, setDeleteModalReservationLine] = useState<{
    id: string;
    reservationId: string;
  } | null>(null);

  return {
    edit: {
      opened,
      setOpened,
    },
    remove: {
      opened: deleteModalReservationLine,
      setOpened: setDeleteModalReservationLine,
    },
  };
}

export const LedgerReservationInfoCard = (reservation: Reservation) => {
  const { open: openListingDetailDrawer } = useListingDetailDrawer();

  const userDataKeys = Object.keys(reservation?.userData || {});

  const setView = useQueryParamSet('view', StringParam);

  return (
    <DrawerInfoCard
      mb={0}
      rows={[
        {
          icon: LoaderIcon,
          title: 'Status',
          text: <ReservationStatusBadge status={reservation.status} />,
        },
        {
          icon: HomeIcon,
          title: 'Listing',
          text: reservation.listing.id ? (
            <Anchor
              onClick={() =>
                openListingDetailDrawer(
                  reservation.listing.id!,
                  'overview',
                  'push'
                )
              }
            >
              {reservation.listing.name}
            </Anchor>
          ) : null,
        },
        {
          icon: CalendarEventIcon,
          title: 'Booked',
          text: `${
            reservation.bookedAt
              ? utc(reservation.bookedAt).format('MMM DD, YYYY')
              : 'unknown date'
          }, ${toTitleCase(reservation.channel || 'unknown channel')}`,
        },
        {
          icon: CrossCircleIcon,
          title: 'Cancelled At',
          text:
            reservation.status === 'cancelled' && reservation.cancelledAt
              ? utc(reservation.cancelledAt).format('MMM DD, YYYY')
              : undefined,
        },
        {
          icon: CalendarDatesIcon,
          title: 'Dates',
          text: [reservation.checkIn, reservation.checkOut]
            .filter(hasValue)
            .map((x) => utc(x).format('MMM DD, YYYY'))
            .join(' - '),
        },
        {
          icon: UserIcon,
          title: 'User data',
          text: !!userDataKeys.length && (
            <Anchor onClick={() => setView('edit')}>
              {userDataKeys.length} updated key
              {userDataKeys.length > 1 && 's'}
            </Anchor>
          ),
        },

        {
          icon: CreditCardIncomeIcon,
          title: 'Balance',
          text: (
            <ReservationPaymentStatusBadge
              paidStatus={reservation.paidStatus}
              paymentsCentTotal={
                reservation.financials.centTotals.paidCentTotal
              }
              reservationCentTotal={reservation.financials.centTotals.centTotal}
              currency={reservation.currency}
            />
          ),
        },
      ]}
    />
  );
};

const Statistics = (reservation: Reservation) => {
  const theme = useMantineTheme();

  // TOTALS
  const currency = reservation.currency;
  const total = reservation.financials.centTotals.centTotal;
  const pmRevenue = reservation.financials.centTotals.pmRevenueCentTotal;
  const taxes = reservation.financials.centTotals.taxCentTotal;
  const ownerRevenue = reservation.financials.centTotals.ownerRevenueCentTotal;

  // COLORS
  const ownerRevenueColor = theme.colors.blue[5];
  const pmRevenueColor = theme.colors.violet[5];
  const taxesColor = theme.colors.orange[5];
  const borderColor = theme.colors.neutral[2];

  const formatted = {
    pmRevenue: formatCurrency(pmRevenue / 100, currency),
    ownerRevenue: formatCurrency(ownerRevenue / 100, currency),
    taxes: formatCurrency(taxes / 100, currency),
    reservation: formatCurrency(total / 100, currency),
  };

  return (
    <SimpleGrid
      cols={{ lg: 4 }}
      spacing={'1px'}
      sx={{
        backgroundColor: borderColor,
        border: '1px solid',
        borderColor,
        borderRadius: theme.radius.md,
        overflow: 'hidden',
        ' > div': {
          paddingInline: theme.spacing.xs,
          paddingTop: theme.spacing.sm,
          paddingBottom: theme.spacing.xs,
        },
      }}
    >
      <Stat label="Reservation">{formatted.reservation}</Stat>
      <Stat label="PM revenue" color={pmRevenueColor}>
        {formatted.pmRevenue}
      </Stat>
      <Stat label="Taxes" color={taxesColor}>
        {formatted.taxes}
      </Stat>

      <HoverCard withArrow shadow="md" position="bottom-end" arrowOffset={55}>
        <HoverCard.Target>
          <Stat label="Owner revenue" color={ownerRevenueColor}>
            {formatted.ownerRevenue}
          </Stat>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <Stack gap="xs">
            <Group wrap="nowrap" align="flex-start">
              <Text c="gray" flex={1}>
                Reservation
              </Text>
              <Text ta="right" fw={500}>
                {formatted.reservation}
              </Text>
            </Group>
            <Group wrap="nowrap" align="flex-start">
              <Text c="gray" flex={1}>
                PM revenue
              </Text>
              <Text ta="right" fw={500}>
                -{formatted.pmRevenue}
              </Text>
            </Group>
            <Group wrap="nowrap" align="flex-start">
              <Text c="gray" flex={1}>
                Taxes
              </Text>
              <Text ta="right" fw={500}>
                -{formatted.taxes}
              </Text>
            </Group>
          </Stack>
        </HoverCard.Dropdown>
      </HoverCard>

      <Card
        radius={0}
        sx={{
          gridColumn: 'span 4',
        }}
      >
        <Progress.Root size="sm">
          <Tooltip label="PM revenue" withArrow>
            <Progress.Section
              value={(pmRevenue / total) * 100}
              color="violet"
            />
          </Tooltip>
          <Tooltip label="Taxes" withArrow>
            <Progress.Section value={(taxes / total) * 100} color="orange" />
          </Tooltip>
          <Tooltip label="Owner revenue" withArrow>
            <Progress.Section
              value={(ownerRevenue / total) * 100}
              color="blue"
            />
          </Tooltip>
        </Progress.Root>
      </Card>
    </SimpleGrid>
  );
};

const Stat = forwardRef<
  HTMLDivElement,
  {
    color?: string;
    label: string;
    children: string;
  } & ComponentPropsWithoutRef<'div'>
>(({ children, label, color, ...props }, ref) => {
  const theme = useMantineTheme();

  return (
    <Card radius={0} ref={ref} {...props}>
      <Group mb={rem(3)} gap={rem(4)} wrap="nowrap" align="flex-start">
        {color && (
          <Box
            mt={1.5}
            sx={{
              height: rem(8),
              borderRadius: '10px',
              width: '2px',
              flexShrink: 0,
              backgroundColor: color,
            }}
          />
        )}
        <Text c={theme.colors.gray[6]} size={rem(12)}>
          {label}
        </Text>
      </Group>
      <Text fw={500}>{children}</Text>
    </Card>
  );
});

const Financials = ({
  financials: { financials, centTotals, isCancelledAndPending },
  title,
  reservationId,
  status,
  openDeleteAdjustment,
  openEditAdjustment,
}: {
  financials: Reservation['financials'];
  status: Reservation['status'];
  title: string;
  reservationId: string;
  openDeleteAdjustment: ReturnType<
    typeof useAdjustmentModals
  >['remove']['setOpened'];
  openEditAdjustment: ReturnType<
    typeof useAdjustmentModals
  >['edit']['setOpened'];
}) => {
  const theme = useMantineTheme();
  const [showAutoAdjustment, setShowAutoAdjustment] = useState(true);

  const { exluded, included } = useMemo(() => {
    const included = financials.filter((x) => x.isInvoice === 'invoice');
    const exluded = financials.filter((x) => x.isInvoice === 'excluded');

    return {
      included,
      exluded,
    };
  }, [financials]);

  const [teamId] = useTeamId();

  const client = useGqtyClient();

  const [loading, setLoading] = useState(false);

  const total = centTotals.centTotal / 100;

  const currency = included[0]?.currency;

  const isCancelled = status === 'cancelled';

  const columns = useMemo<
    MRT_ColumnDef<Reservation['financials']['financials'][0]>[]
  >(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
        mantineTableFooterCellProps: {
          pl: 'xs',
        },
        mantineTableBodyCellProps: {
          px: 'xs',
        },
        Footer: () => (
          <Text span fw={500}>
            Total guest charged
          </Text>
        ),
        Cell: ({ row }) => {
          const description = row.original.description;

          const isManual = !row.original.connectionId;

          const td = isCancelled && !isManual ? 'line-through' : undefined;

          return <Text td={td}>{description}</Text>;
        },
      },
      {
        header: 'amount',
        accessorKey: 'amount',
        maxSize: 90,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
          pr: 'xs',
        },
        Cell: ({ row }) => {
          const amount = formatCurrency(
            (row.original.centTotal || 0) / 100,
            row.original.currency
          );

          const isManual = !row.original.connectionId;

          const td = isCancelled && !isManual ? 'line-through' : undefined;

          if (isManual) {
            return (
              <Group gap={5} wrap="nowrap">
                <IconButton
                  icon="TrashIcon"
                  size={16}
                  tooltip="Delete adjustment"
                  onClick={() =>
                    openDeleteAdjustment({
                      reservationId,
                      id: row.original.id,
                    })
                  }
                />
                <Text td={td}>{amount}</Text>{' '}
              </Group>
            );
          }

          return <Text td={td}>{amount}</Text>;
        },
        Footer: () => (
          <Text span fw={500} ta="right" display={'block'}>
            {formatCurrency(total, currency)}
          </Text>
        ),
      },
    ],
    [currency, total, reservationId, isCancelled, openDeleteAdjustment]
  );

  const difference = useMemo(
    () => centTotals.paidCentTotal - centTotals.centTotal,
    [centTotals.centTotal, centTotals.paidCentTotal]
  );

  const autobalance = useCallback(async () => {
    setLoading(true);

    const cancellationLineType = await client
      .query((q) => {
        return (
          q
            .paymentLineClassifications({
              where: {
                lines: {
                  tenantId: { _eq: teamId },
                },
                type: { _eq: 'reservationLine' },
                name: { _ilike: '%cancellation%' },
                lineTypeAccounts: {},
              },
            })
            .map((line) => line.name)[0] ?? null
        );
      })
      .catch(() => null);

    openEditAdjustment({
      centTotal: difference,
      party: 'owners',
      description: 'Cancellation revenue',
      reservationId,
      lineType: cancellationLineType,
    });

    setLoading(false);
  }, [client.query, teamId, reservationId, difference, openEditAdjustment]);

  return (
    <>
      {isCancelledAndPending && showAutoAdjustment && (
        <Box
          sx={(theme) => ({
            boxShadow: theme.shadows.md,
            // zIndex: 100,
            border: '1px solid',
            borderColor: theme.colors.yellow[5],
            backgroundColor: theme.colors.yellow[0],
            padding: `${theme.spacing.sm} ${theme.spacing.sm}`,
            borderRadius: theme.radius.md,
          })}
        >
          <Title order={5} fw={500} mb={rem(5)} c={theme.colors.neutral[7]}>
            Reservation Cancelled - Action Required
          </Title>

          <Text mb="md" size="xs" c="neutral">
            This reservation was canceled, leaving a balance of{' '}
            {formatCurrency(centTotals.paidCentTotal / 100, currency)} from
            previous payments.
          </Text>

          <Group mb={rem(8)} gap="xs" justify="flex-start">
            <Icon icon="CalendarCrossIcon" size={16} />
            <Text size="sm" c={theme.colors.neutral[7]}>
              Cancelled Reservation:
            </Text>
            <Badge
              color="teal"
              size="md"
              sx={{
                marginLeft: 'auto',
              }}
            >
              {formatCurrency(centTotals.centTotal / 100, currency)}
            </Badge>
          </Group>

          <Group mb="md" gap="xs" justify="flex-start">
            <Icon icon="CreditCardIncomeIcon" size={16} />
            <Text size="sm" c={theme.colors.neutral[7]}>
              Current Payments Total:
            </Text>
            <Badge
              color="blue"
              size="md"
              sx={{
                marginLeft: 'auto',
              }}
            >
              {formatCurrency(centTotals.paidCentTotal / 100, currency)}
            </Badge>
          </Group>

          <Group justify="end">
            <Button
              size="xs"
              onClick={() => setShowAutoAdjustment(false)}
              disabled={loading}
            >
              Close
            </Button>
            <Button
              size="xs"
              variant="primary"
              onClick={autobalance}
              loading={loading}
            >
              Add {formatCurrency(difference / 100, currency)} adjustment
            </Button>
          </Group>
        </Box>
      )}
      <DrawerCollapsableTable
        title={title}
        customRowCount={
          !exluded.length ? undefined : (
            <>
              {included.length}{' '}
              <HoverCard withArrow shadow="md">
                <HoverCard.Target>
                  <Text span size="xs">
                    ({exluded.length})
                  </Text>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Stack gap="xs">
                    <Text fw={500} flex={1}>
                      Exluded financials
                    </Text>
                    {exluded.map((line) => (
                      <Group key={line.id} wrap="nowrap" align="flex-start">
                        <Text c="gray" flex={1}>
                          {line.description}
                        </Text>
                        <Text ta="right" fw={500}>
                          {formatCurrency(
                            (line.centTotal ?? 0) / 100,
                            currency
                          )}
                        </Text>
                      </Group>
                    ))}
                  </Stack>
                </HoverCard.Dropdown>
              </HoverCard>
            </>
          )
        }
        rightSection={
          <Button
            variant="light"
            size="xs"
            leftIcon="PlusIcon"
            color="neutral"
            iconColor={theme.colors.neutral[5]}
            onClick={() =>
              openEditAdjustment({
                centTotal: undefined,
                description: '',
                lineType: null,
                reservationId,
                party: 'owners',
              })
            }
          >
            Add adjustment
          </Button>
        }
        rowData={included}
        columns={columns}
        emptyRowsFallback={() => (
          <Center>
            <Text size="sm" c="gray">
              No financials available
            </Text>
          </Center>
        )}
      />
    </>
  );
};

const Deposits = ({
  deposits,
  currency,
  title,
}: {
  deposits: Reservation['deposits'];
  title: string;
  currency: string;
}) => {
  const { open: openDeposit } = useDepositDetailDrawer();

  const total = sum(deposits, 'paymentCentTotal') / 100;

  const columns = useMemo<MRT_ColumnDef<Reservation['deposits'][0]>[]>(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
        Cell: ({ row }) => {
          const deposit = row.original;

          return (
            <Box>
              <Text lineClamp={2}>{deposit.description}</Text>
              <Text c="gray" size="xs">
                {deposit.date ? utc(deposit.date).format('LL') : ''}
              </Text>
            </Box>
          );
        },
      },
      {
        header: 'Badge',
        maxSize: 0,
        minSize: 120,
        size: 120,
        Cell: ({ row }) => {
          const connection = row.original.connection;

          return (
            <Group
              wrap="nowrap"
              gap={5}
              sx={(theme) => ({
                backgroundColor: theme.colors.gray[1],
                border: `1px solid ${theme.colors.gray[2]}`,
                paddingInline: rem(6),
                paddingBlock: rem(3),
                borderRadius: theme.radius.xl,
              })}
            >
              {connection?.app.iconRound ? (
                <Avatar
                  size={16}
                  radius="xl"
                  mr={rem(3)}
                  src={connection.app.iconRound}
                />
              ) : (
                <Icon icon="PlusCircleIcon" size={14} />
              )}
              <Text span size="xs">
                {connection?.app.name || 'Manual'}
              </Text>
            </Group>
          );
        },
      },
      {
        header: 'amount',
        accessorKey: 'centTotal',
        maxSize: 0,
        minSize: 90,
        size: 90,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          return formatCurrency(row.original.paymentCentTotal / 100, currency);
        },
      },
    ],
    [currency]
  );

  return (
    <DrawerCollapsableTable
      title={title}
      rightSection={
        <Text size="sm" fw={500}>
          {formatCurrency(total, currency)}
        </Text>
      }
      rowData={deposits}
      columns={columns}
      onRowClick={{
        handler: (row) => {
          if (row.original.id) openDeposit(row.original.id, 'push');
        },
        disabled: (row) => !row.original.id,
      }}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" c="gray">
            No deposits found
          </Text>
        </Center>
      )}
    />
  );
};

const FeesAndCommissions = ({
  rowData,
  currency,
  title,
  openDeleteAdjustment,
  openEditAdjustment,
  reservationId,
}: {
  rowData: Reservation['recurringFees'];
  reservationId: string;
  openDeleteAdjustment: ReturnType<
    typeof useAdjustmentModals
  >['remove']['setOpened'];
  openEditAdjustment: ReturnType<
    typeof useAdjustmentModals
  >['edit']['setOpened'];
  title: string;
  currency: string;
}) => {
  const theme = useMantineTheme();

  const total = sum(rowData, (x) => x.centTotal) / 100;

  const columns = useMemo<MRT_ColumnDef<Reservation['recurringFees'][0]>[]>(
    () => [
      {
        header: 'Title',
        accessorKey: 'title',
        Footer: () => (
          <Text span fw={500}>
            Total to PM
          </Text>
        ),
        mantineTableFooterCellProps: {
          pl: 'xs',
        },
      },
      {
        header: 'amount',
        accessorKey: 'centTotal',
        maxSize: 0,
        minSize: 90,
        size: 90,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
          pr: 'xs',
        },
        Cell: ({ row }) => {
          const amount = formatCurrency(
            row.original.centTotal / 100,
            row.original.currency
          );

          const isManual = row.original.isAdjustment;

          if (isManual) {
            return (
              <Group gap={5} wrap="nowrap">
                <IconButton
                  icon="TrashIcon"
                  size={16}
                  tooltip="Delete adjustment"
                  onClick={() =>
                    openDeleteAdjustment({
                      reservationId,
                      id: row.original.id,
                    })
                  }
                />
                <Text
                  sx={{
                    flexShrink: 0,
                  }}
                >
                  {amount}
                </Text>
              </Group>
            );
          }

          return <Text>{amount}</Text>;
        },
        Footer: () => {
          return (
            <Text span fw={500} ta="right" display={'block'}>
              {formatCurrency(total, currency)}
            </Text>
          );
        },
      },
    ],
    [currency, total, openDeleteAdjustment, reservationId]
  );

  return (
    <DrawerCollapsableTable
      title={title}
      rowData={rowData}
      columns={columns}
      rightSection={
        <Button
          variant="light"
          size="xs"
          leftIcon="PlusIcon"
          color="neutral"
          iconColor={theme.colors.neutral[5]}
          onClick={() =>
            openEditAdjustment({
              centTotal: undefined,
              description: '',
              lineType: null,
              reservationId,
              party: 'manager',
            })
          }
        >
          Add adjustment
        </Button>
      }
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" c="gray">
            No fees and commissions found
          </Text>
        </Center>
      )}
    />
  );
};

const ReservationTimeline = ({ data }: { data: Reservation['timeline'] }) => {
  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  if (!data.length) return null;

  return (
    <Box>
      <Collapse
        title={
          <Text component="span" size="sm">
            Timeline
            <Text
              c={colorScheme === 'dark' ? colors.gray[6] : 'gray'}
              ml="xs"
              size="xs"
              component="span"
            >
              {data.length}
            </Text>
          </Text>
        }
        minHeight={30}
        defaultOpened={!!data.length}
      >
        <Timeline data={data} order="desc" />
      </Collapse>
    </Box>
  );
};
