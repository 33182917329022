import type { gqlV2 } from '@finalytic/data';
import type {
  recurringFeeType_enum,
  taxBehavior_enum,
} from '@finalytic/graphql';
import { IconDefinition } from '@finalytic/icons';
import { useFormContext } from 'react-hook-form';

export type FeeFormInputs = {
  id: string | undefined;
  title: string;
  defaultRate: number;
  triggerValue: FeeFormInputsReservationTriggerValue;
  creditParty: gqlV2.party_enum;
  debitParty: gqlV2.party_enum;
  creditAccountId: string;
  debitAccountId: string;
  taxRateId: string | null;
  taxRateBehavior: taxBehavior_enum;
  bookingChannelsFilter: FeeFormInputsBookingChannelsFilter;
  linkedAccountIds: string[];
  type: recurringFeeType_enum;
};

type Extract<T, U extends T> = T extends U ? T : never;

export const DEFAULT_FEE_ICON: FeeIcon = 'PercentageIcon';

export type FeeIcon = Extract<
  IconDefinition,
  | 'BedIcon'
  | 'HomeIcon'
  | 'OfficeIcon'
  | 'PercentageIcon'
  | 'StripeIcon'
  | 'BookingComIcon'
  | 'Ironing3Icon'
  | 'GardenCartIcon'
  | 'PoolIcon'
  | 'TractorIcon'
  | 'PaintIcon'
  | 'WashMachineIcon'
  | 'GrillIcon'
>;

export type FeeFormInputsReservationTriggerValue =
  | 'checkIn'
  | 'checkOut'
  | 'bookedAt'
  | 'cancelledAt'
  | 'proRata';

export type FeeFormInputsBookingChannelsFilter = {
  _in?: string[];
  _nin?: string[];
};

export const useFeeForm = () => useFormContext<FeeFormInputs>();
