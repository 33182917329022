import type { TimelineItem } from '@finalytic/components';
import { Query, useDashboard, useQuery, useTeam } from '@finalytic/data';
import { type Maybe, hasValue, sum, toTitleCase } from '@finalytic/utils';
import { useDebouncedValue } from '@mantine/hooks';
import {
  Dashboard,
  formatUserName,
  getListingName,
} from '@vrplatform/ui-common';
import { getDepositLines } from '../../../views/deposits/edit/get-deposit-lines';
import { getLedgerReservationFinancials } from '../_utils';

export function getLedgerReservationDetail(
  q: Query,
  args: {
    id: Maybe<string>;
    dashboard: Dashboard;
    teamId: string;
    partnerId: string;
  }
) {
  if (!args.id) return null;

  const deposits = q
    .transactions({
      where: {
        type: { _eq: 'deposit' },
        lines: {
          reservationId: { _eq: args.id },
        },
      },
      order_by: [{ date: 'desc_nulls_last' }],
    })
    .map((deposit) => {
      const connection = {
        id: deposit?.connection?.id,
        name: deposit?.connection?.name,
        app: {
          id: deposit?.connection?.app?.id,
          iconRound: deposit?.connection?.app?.iconRound,
          name: deposit?.connection?.app?.name,
        },
      };

      const lines = getDepositLines(deposit.lines, {
        where: {
          reservationId: { _eq: args.id },
        },
        orderBy: [
          {
            transaction: { date: 'desc_nulls_last' },
          },
          {
            createdAt: 'desc_nulls_last',
          },
        ],
      }).lines.reservations;

      const paymentCentTotal = sum(lines, 'paymentCentTotal');

      return {
        id: deposit?.id,
        description: deposit?.description,
        currency: deposit?.currency ?? 'usd',
        date: deposit?.date,
        connection: connection?.id ? connection : null,
        paymentCentTotal,
        lines,
      };
    })
    .filter((x) => !!x.paymentCentTotal);

  return (
    q
      .reservations({
        where: {
          id: { _eq: args.id },
        },
      })
      .map((reservation) => {
        const financials = getLedgerReservationFinancials(reservation, {
          partnerId: args.partnerId,
          tenantId: args.teamId,
        });

        const timeline =
          args.dashboard === 'owner'
            ? []
            : reservation
                .changeSourceLinks({
                  order_by: [{ created_at: 'desc' }],
                })
                .map<TimelineItem>((sourceChange) => {
                  const getIcon = () => {
                    const automation = sourceChange?.change.automation;

                    const left = {
                      id: automation?.leftConnection?.appId,
                      icon: automation?.leftConnection?.app?.iconRound,
                    };
                    const right = {
                      id: automation?.rightConnection?.appId,
                      icon: automation?.rightConnection?.app?.iconRound,
                    };

                    const apps = [left, right].filter(
                      (x) => x?.id !== 'finalytic'
                    );

                    return apps[0]?.icon || null;
                  };

                  return {
                    id: sourceChange.change.id,
                    label: [
                      toTitleCase(
                        sourceChange.change.entityUniqueRef?.split('/').at(0)
                      ),
                      sourceChange.change.message,
                    ]
                      .filter(hasValue)
                      .join(' - '),
                    date: sourceChange.created_at,
                    icon: getIcon(),
                  };
                });

        const reservationlistingConnectionListing = {
          id: reservation.listingConnection?.listing?.id,
          name: getListingName(reservation.listingConnection?.listing),
        };

        const reservationListing = {
          id: reservation.listingId,
          name: getListingName(reservation.listing),
        };

        return {
          id: reservation.id as string,
          bookedAt: reservation.bookedAt,
          connection: {
            id: reservation.connection?.id,
            app: {
              id: reservation.connection?.app?.id,
              iconRound: reservation.connection?.app?.iconRound,
              name: reservation.connection?.app?.name,
            },
          },
          channel:
            reservation.channel?.uniqueRef || reservation.bookingPlatform,
          cancelledAt: reservation.cancelledAt,
          checkIn: reservation.checkIn,
          uniqueRef: reservation.uniqueRef,
          checkOut: reservation.checkOut,
          guestName: reservation.guestName,
          status: reservation.status,
          centTotal: reservation.centTotal,
          centPaid: reservation.centPaid ?? 0,
          paidStatus: reservation.paidStatus,
          userData: reservation.userdata(),
          confirmationCode: reservation.confirmationCode,
          pmsReferenceCode: reservation.pmsReferenceCode,
          listing: reservationListing?.id
            ? reservationListing
            : reservationlistingConnectionListing,
          currency: reservation.currency || 'usd',
          financials,
          deposits,
          timeline,
          recurringFees: financials.recurringFees,
          files: reservation
            ?.files({
              order_by: [{ startDate: 'desc_nulls_last' }],
            })
            .map((file) => ({
              id: file.id,
              type: file.type,
              filename: file.filename,
              ownerName: file.owner && formatUserName(file.owner),
              startDate: file.startDate,
              endDate: file.endDate,
            })),
          // hasListingOwnership: !!reservation.listing
          //   ?.ownershipPeriods({
          //     limit: 1,
          //   })
          //   .map((x) => x.id)[0],
          // paymentLines: paymentLines.filter((x) => x.isResevationPayment),
          // resolutionLines: paymentLines.filter(
          //   (x) => !x.isResevationPayment
          // ),
        };
      })[0] || null
  );
}

export function useLedgerReservationDetailDrawerQuery(id: Maybe<string>) {
  const [{ id: teamId, partnerId }] = useTeam();
  const [dashboard] = useDashboard();

  const query = useQuery(
    (q, args) => {
      if (!args.id) return null;

      return getLedgerReservationDetail(q, args);
    },
    {
      skip: !id,
      queryKey: 'reservations',
      variables: {
        id,
        teamId,
        partnerId,
        dashboard,
      },
    }
  );

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}

export type Reservation = NonNullable<
  ReturnType<typeof useLedgerReservationDetailDrawerQuery>['data']
>;
